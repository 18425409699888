import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import { blogEntries } from '../static/blogs';
import { BlogEntry } from '../components/BlogEntry';
import { butter } from '../services/buttercms';
import { graphql } from 'gatsby';
import AcademyHeader from '../components/header/AcademyHeader';
import { NavBar } from '../components/segments/NavBar';
import { Helmet } from 'react-helmet';
import DarkFooter from '../components/segments/DarkFooter';

// butter.post.list({ page: 1, page_size: 10 }).then(response => {
//   // console.log(response);
// });

export default ({ data }) => {
  // console.log('data: ', data);
  return (
    <IndexLayout>
      <Helmet>
        <title>How to Develop An App | Blog Posts, eBooks, & More | Rapptr Labs</title>
        <meta
          name="description"
          content="A resource center for startups & brands looking to learn more about how to develop an app. Advice on strategy, development, marketing, funding, and more."
        />
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar alwaysOpaque />
      <AcademyHeader />
      <BlogDiv>
        {data.allButterPost.nodes
          .sort((a, b) => new Date(b.published).getTime() - new Date(a.published).getTime())
          .map((blogEntry, i) => (
            <BlogEntry key={blogEntry.id} {...blogEntry} align={i % 2 === 0 ? 'left' : 'right'} />
          ))}
      </BlogDiv>
      <DarkFooter />
    </IndexLayout>
  );
};

const BlogDiv = styled.div`
  display: block;
  margin: auto;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 120px;
  /* padding-bottom: 100px; */
  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 0px;
    overflow: hidden;
    padding-top: 0px;
  }
`;

const BlogRow = styled.div`
  display: block;
  box-sizing: border-box;
`;
export const query = graphql`
  query {
    allButterPost {
      nodes {
        body
        published(formatString: "MMMM DD YYYY")
        featured_image
        title
        url
        summary
        slug
        id
        author {
          first_name
          last_name
        }
      }
    }
  }
`;
