import { IndexLayout } from '../../layouts';
import styled from 'styled-components';
import { RapptrAcademy } from '../../assets';
export default () => (
  <Div>
    {/* <SiteIcon>
      <StyledAcademyImg src={RapptrAcademy} alt="" />
    </SiteIcon> */}
  </Div>
);

const Div = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
  background-image: linear-gradient(to bottom, #151a1a, #090b0b);
  margin: 0;
  padding: 0 33px 0 33px;
`;

const SiteIcon = styled.div`
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  overflow: hidden;
`;

const StyledAcademyImg = styled.img`
  width: 106px;
`;

const SiteTitle = styled.h1`
  font-family: RegulatorNova;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.77px;
  text-align: center;
  color: #fff;
`;
