import * as React from 'react';
import { BlogEntryType } from '../static/blogs';
import { navigate, Link } from 'gatsby';
import styled from 'styled-components';
import { theme } from '../utils/theme';
//import TransitionLink from "gatsby-plugin-transition-link"
//import AniLink from "gatsby-plugin-transition-link/AniLink"

interface BlogEntryAlignment {
  align: 'left' | 'right';
}

export const BlogEntry = ({
  slug,
  title,
  summary,
  featured_image,
  published,
  align,
}: BlogEntryType | BlogEntryAlignment) => {
  return (
    <Main key={slug}>
      <Link to={`/academy/${slug}`}>
        {/* <BlogDiv key={slug} align={align}> */}
        <ThisImage src={featured_image} alt={title} onClick={() => navigate(`/academy/${slug}`)} />
        {/* <DummySpace /> */}
        <BlogContent>
          <BlogDateText align={align}>{published.slice(0, -4).toUpperCase()}</BlogDateText>
          <BlogTitleText align={align}>{title}</BlogTitleText>
          {/* <BlogContentText align={align}>{summary}</BlogContentText> */}
        </BlogContent>
        {/* </BlogDiv> */}
      </Link>
      {/* <HR align={align} /> */}
    </Main>
  );
};

// const BlogDiv = styled.div<BlogEntryAlignment>`
//   width: 100%;
//   height: auto;
//   margin-top: 30px;
//   display: flex;
//   justify-content: flex-start;
//   flex-direction: ${props => (props.align === 'left' ? 'row' : 'row-reverse')};
// `;

const BlogContent = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.25s;
  transition: 0.25s;
  will-change: transform background-color;
  overflow: hidden;
  :hover {
    -webkit-transform: translate(0px, -10px);
    transform: translate(0px, -10px);
    background-color: rgba(0, 0, 0, 0.85);
  }
`;

const BlogDiv = styled.div<BlogEntryAlignment>`
  margin: auto;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 120px;
`;
const ThisImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 410px;

  -webkit-transition: 0.5s;
  transition: 0.5s;
  will-change: transform background-color opacity;

  -webkit-animation: contentFadeIn 1.5s normal forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: contentFadeIn 1.5s normal forwards; /* Firefox < 16 */
  -ms-animation: contentFadeIn 1.5s normal forwards; /* Internet Explorer */
  -o-animation: contentFadeIn 1.5s normal forwards; /* Opera < 12.1 */
  animation: contentFadeIn 1.5s normal forwards;
  :hover {
    cursor: pointer;
  }
`;
const Main = styled.div`
  width: 30%;
  height: 400px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin: 10px;
  @media (max-width: 749px) {
    width: 100%;
    height: 400px;
    display: inline-block;
    position: relative;
    margin: 0px;
    &:nth-child(n) {
      width: 100%;
      height: 400px;
    }
  }

  &:nth-child(2n) {
    width: 60%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  &:nth-child(3n) {
    width: 30%;
    height: 400px;
  }
  &:nth-child(4n) {
    width: 60%;
    height: 400px;
  }
  &:nth-child(5n) {
    width: 60%;
    height: 400px;
  }
  &:nth-child(6n) {
    width: 30%;
    height: 400px;
  }
  &:nth-child(7n) {
    width: 30%;
    height: 400px;
  }
  &:nth-child(19n) {
    width: 60%;
    height: 400px;
  }
  &:nth-child(n + 44) {
    width: 30%;
    height: 400px;
  }
  &:nth-child(1) {
    width: 60%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }

  &:nth-child(2) {
    width: 30%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }

  &:nth-child(3) {
    width: 29%;
    height: 820px;
    float: left;
    ${ThisImage} {
      height: 830px;
    }
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
      float: none;
    }
  }

  &:nth-child(4) {
    width: 60%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  &:nth-child(5) {
    width: 30%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  &:nth-child(6) {
    width: 30%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  &:nth-child(7) {
    width: 30%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }

  &:nth-child(8) {
    width: 60%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  &:nth-child(9) {
    width: 60%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  &:nth-child(10) {
    width: 30%;
    height: 400px;
    @media (max-width: 749px) {
      width: 100%;
      height: 400px;
    }
  }
  :hover {
    ${BlogContent} {
      -webkit-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
      background-color: rgba(0, 0, 0, 0.85);
    }
    ${ThisImage} {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
`;

BlogDiv.defaultProps = {
  align: 'left',
};

const DummySpace = styled.div`
  width: 60px;
`;

// const BlogContent = styled.div`
//   display: flex;
//   flex-direction: column;

// `;

const BlogDateText = styled.h5<BlogEntryAlignment>`
  /* font-family: regulator-nova; */
  font-family: ${theme.SECONDARY_FONT};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.18px;
  color: white;
  text-align: ${props => props.align};
`;

const BlogTitleText = styled.h1<BlogEntryAlignment>`
  /* font-family: regulator-nova; */
  font-family: ${theme.SECONDARY_FONT};
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.33px;
  color: white;
  text-align: ${props => props.align};
`;

const BlogContentText = styled.p<BlogEntryAlignment>`
  font-family: Avenir;
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.1px;
  text-align: ${props => props.align};
  color: #000;
  max-width: 520px;
`;

const HR = styled.hr<BlogEntryAlignment>`
  height: 0.4px;
  opacity: 0.25;
  border: solid 0.6px #000;
  width: 90%;
  align-self: ${props => (props.align === 'left' ? 'flex-start' : 'flex-end')};
`;
